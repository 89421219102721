import { Col, Container, Row } from "react-bootstrap";
import { DynamicText } from "@/ui/DynamicText";
import { DynamicHtml } from "@/ui/DynamicHtml";
import classes from "./styles.module.scss";
import clsx from "classnames";
import { Details } from "@/ui/Details";
import { useLocale } from "@/hooks/useLocale";

export const SeriousAboutTalent = ({ className, tag, title, text, details, lg, children }) => {
  const locale = useLocale();

  return (
    <div className={clsx(className, classes.content)}>
      <Container className={clsx("px-2 px-md-3", { "pt-5": !!tag })}>
        {tag && <DynamicText path={tag} className={classes.content__tag} />}
        <Row className="position-relative pt-md-5">
          <Col xs={12} lg={lg} className="px-3">
            <div className="mt-4 me-3">
              <DynamicText
                path={typeof title === "string" ? title : `${title.path}.${locale}`}
                tag="h3"
                className="h4 title-bar-primary mb-4"
                dangerouslySetInnerHTML={title.placeholders ? { __html: title.placeholders[locale] } : null}
              />
              <DynamicHtml path={text} />
            </div>
            {details && (
              <Details>
                <DynamicHtml path={details} />
              </Details>
            )}
          </Col>
          {children}
        </Row>
      </Container>
    </div>
  );
};

SeriousAboutTalent.defaultProps = {
  lg: 6,
  tag: false,
  details: false,
};
